import { UserType } from "../graphql/types";

// DO NOT use any captial letters in any of the redirect URIs cuz it messes with the exact matches
export enum RedirectUri {
  errorGeneral = "/error",
  login = "/login",
  logout = "/logout",
  suspendedChamberMember = "/suspended",
  newPassword = "/newpassword",
  forgotPassword = "/forgotpassword",
  cpaMarketingToolkit = "/marketing",
  updateAChamberRoute = "/admin/chambers/update",
  listAllChamberAdmin = "/admin/chambers",
  createAChamber = "/admin/chambers/create",
  listAllPerkPartner = "/admin/perkpartners",
  createAPerkPartner = "/admin/perkpartners/create",
  editperkPartnerDiscountPageChamberAdmin = "/chambers/perkpartnerdiscounts/update",
  manageAMemberChamberAdmin = "/chambers/members",
  updateAMemberChamberAdmin = "/chambers/members/update",
  manageYourChamberChamberAdmin = "/chambers/update",
  createAMemberChamberAdmin = "/chambers/create",
  importAMemberChamberAdmin = "/chambers/import",
  settingsOfChamberChamberAdmin = "/chambers/support",
  memberDiscountPageChamberAdmin = "/chambers/discounts",
  memberDiscountCreatePageChamberAdmin = "/chambers/discounts/create",
  editDiscountPageChamberAdmin = "/chambers/discounts/update",
  manageDiscountChamberMemberAdmin = "/chambers/members/discounts",
  createDiscountChamberMemberAdmin = "/chambers/members/discounts/create",
  editDiscountChamberMemberAdmin = "/chambers/members/discounts/update",
  settingsChamberMemberAdmin = "/chambers/members/support",
  rootAdmin = "/home",
  chamberAdmin = "/chamber/admin/home",
  chamberMemberAdmin = "/chamber/member/home",
  home = "/",
  deleteCpaData = "/deletecpadata",
  cpacreateperk = "/cpacreateperk", // This is specifically for the "Create Perk" button in app to route user properly to perk create screen
  perksLink = "/perksLink",
  chamberShopLocal = "/chamber/previousshoplocal",
  activeChamberShopLocal = "/chamber/activeshoplocal",
  createShopLocal = "/shoplocal/create",
  updateShopLocal = "/shoplocal/update",
  chamberMemberShopLocal = "/chamber/member/shoplocal",
  cpaPrivacyPolicy = "/cpaprivacypolicy",
  chamberAdminManualLaunchGuide = "/chamber/manualandlaunchguide",
  rootManageChamberEngage = "/chamberengage",
  chamberEngageManageMemberProfileAndPerkBanners = "/chamberengage/memberprofileandperkbanners",
  chamberEngageManageCategoryAdvertisingBanners = "/chamberengage/categoryadvertisingbanners",
  chamberEngageManageCategoryAdvertisingBannersChamberMember = "/chamberengage/categoryadvertisingbanners/member",
  chamberReward = "/chamber/previousreward",
  activeChamberReward = "/chamber/activereward",
  createReward = "/reward/create",
  updateReward = "/reward/update",
}

const enumStrings = {
  supportContactEmail: "support@chamberperksapp.com",
  subscriptionsContactEmail: "subscriptions@chamberperksapp.com",
  sessionStorageKey: {
    loggedInWithTemporaryPassword: "loggedInWithTemporaryPassword",
  },
  modalMessage: {
    deleteConfirm: "Are you sure you want to delete?",
    doneConfirm: "Are you sure you want to mark as done?",
  },
  chamberModal: {
    downloadText: "Download",
    okText: "Ok",
    noText: "No",
    yesText: "Yes",
  },
  errorPageText: {
    title: "An Error Has Occured",
    text: `We apologize for this inconvenience. Please try going to home or
    contacting support if this is happening repeatedly.`,
    homeButtonText: "Go Home",
    contactSupportButtonText: "Contact Support",
  },
  navText: {
    cpaNameText: "CPA",
    rootAdminText: "Root Dashboard",
    chamberAdminText: "Chamber Dashboard",
    boardAdminText: "Board Dashboard",
    chamberMemberAdminText: "Member Dashboard",
    perkPartnerAdminText: "Perk Partner Dashboard",
  },
  categoriesText: {
    categoriesTitle: "Chamber Perks App™ Categories *",
  },
  redeemOfferText: {
    redeemOfferTitle: "Where to Redeem Perk",
    tooltip: {
      title:
        "Select from the 3 options below how users can view and redeem this perk. For more details click the “information icon” beside each option.",
      inPerson:
        "By selecting this option, Chamber Perks App™ users can only redeem this perk in person by showing the redemption screen on their phone at the point of purchase. This is a good option to drive more traffic to your company.",
      online:
        "By selecting this option, you are setting up a perk that can ONLY be redeemed online. After selecting this option, you must enter a discount code and your company e-commerce website URL. This is a good option to grow your e-commerce business.",
      contactCompany:
        "By selecting this option, you are setting up a perk that can ONLY be redeemed by contacting your company. After selecting this option, you must enter a department or contact name, contact email, and contact number. This is a good option for B2B businesses.",
    },
  },
  chamberShopLocal: {
    activeTitle: "View Active Shop Local Contest",
    activeDescription:
      "View prize draw entrants, participating member companies and update active Shop Local contest.",
    inactiveTitle: "View Previous Shop Local Contests",
    inactiveDescription: "",
    toastText: "Contest Created Successfully",
    updateToastText: "Contest Updated Successfully",
    label: {
      prizeDescription: "Contest Prize Description",
      campaignName: "Shop Local Contest Name",
    },
    tooltip: {
      campaignName:
        "Name of Shop Local Contest. This name will not be shown to Chamber Perks App™ users and is only shown in your Chamber Dashboard.",
      prizeDescription:
        "Description of prize that Chamber Perks App™ users can qualify to win by participating in the Shop Local Contest.",
      startDateUtcMilli:
        "Start date of this Shop Local Contest. This contest will automatically start at 12:00am on the selected date.",
      endDateUtcMilli:
        "End date of this Shop Local Contest. This contest will automatically end at 11:59pm on the selected date.",
      participatingMembers:
        'Select the members that are participating in this Shop Local Contest. Each particiapting member has a unique participation code for this contest. When a Chamber Perks App™ user redeems any perk offered by a participating member, the user can enter the member`s code to be entered into the prize draw. Members can also see their participation code from their Member Dashboard on the "Shop Local" page.',
    },
  },
  rewardCampaign: {
    activeTitle: "View Active Reward Campaign",
    activeDescription:
      "View prize draw entrants and update active Reward Campaign.",
    inactiveTitle: "View Previous Reward Campaigns",
    inactiveDescription: "",
    toastText: "Reward Campaign Created Successfully",
    updateToastText: "Reward Campaign Updated Successfully",
    label: {
      prizeDescription: "Reward Campaign Prize Description",
      campaignName: "Reward Campaign Name",
    },
    tooltip: {
      sponsoringMember:
        "Select your member that is sponsoring this Reward Campaign. This member will be the one providing the prize to the winner of this campaign. You can also select yourself as the sponsoring member.",
      campaignName:
        "Name of Reward Campaign. This name will not be shown to Chamber Perks App™ users and is only shown in your Chamber Dashboard.",
      prizeDescription:
        "Description of prize that Chamber Perks App™ users can earn a chance to win by participating in the Reward Campaign.\n\nThis verbiage will be shown as part of a larger description, please see below the text box for more details.",
      startDateUtcMilli:
        "Start date of this Reward Campaign. This reward campaign will automatically start at 12:00am on the selected date.",
      endDateUtcMilli:
        "End date of this Reward Campaign. This reward campaign will automatically end at 11:59pm on the selected date.",
    },
  },
  chamberAdminImportMembersText: {
    importCSVText: "You can import a csv file of members here. Please click",
    hereText: "here",
    toDownloadText:
      "to download a sample to see how you should format your file.",
    importMembersButtonText: "IMPORT MEMBERS",
  },
  chamberAdminListAllChambersText: {
    noChambersText: "No members available please create one",
    downloadAllMemberCredentials: {
      buttonLabel: "Download All Member Login Credentials",
      initialModal: {
        title: "Download All Member Login Credentials",
      },
    },
  },
  provinceDropDownSelect: {
    SelectProvinceText: "Province/State",
    dropdownOptions: [
      { full: "Alberta", code: "AB", country: "Canada" },
      { full: "British Columbia", code: "BC", country: "Canada" },
      { full: "Manitoba", code: "MB", country: "Canada" },
      { full: "New Brunswick", code: "NB", country: "Canada" },
      { full: "Newfoundland and Labrador", code: "NL", country: "Canada" },
      { full: "Northwest Territories", code: "NT", country: "Canada" },
      { full: "Nova Scotia", code: "NS", country: "Canada" },
      { full: "Nunavut", code: "NU", country: "Canada" },
      { full: "Ontario", code: "ON", country: "Canada" },
      { full: "Prince Edward Island", code: "PE", country: "Canada" },
      { full: "Quebec", code: "QC", country: "Canada" },
      { full: "Saskatchewan", code: "SK", country: "Canada" },
      { full: "Yukon", code: "YT", country: "Canada" },
      { full: "Alabama", code: "AL", country: "United States" },
      { full: "Alaska", code: "AK", country: "United States" },
      { full: "Arizona", code: "AZ", country: "United States" },
      { full: "Arkansas", code: "AR", country: "United States" },
      { full: "California", code: "CA", country: "United States" },
      { full: "Canal Zone", code: "CZ", country: "United States" },
      { full: "Colorado", code: "CO", country: "United States" },
      { full: "Connecticut", code: "CT", country: "United States" },
      { full: "Delaware", code: "DE", country: "United States" },
      { full: "District of Columbia", code: "DC", country: "United States" },
      { full: "Florida", code: "FL", country: "United States" },
      { full: "Georgia", code: "GA", country: "United States" },
      { full: "Guam", code: "GU", country: "United States" },
      { full: "Hawaii", code: "HI", country: "United States" },
      { full: "Idaho", code: "ID", country: "United States" },
      { full: "Illinois", code: "IL", country: "United States" },
      { full: "Indiana", code: "IN", country: "United States" },
      { full: "Iowa", code: "IA", country: "United States" },
      { full: "Kansas", code: "KS", country: "United States" },
      { full: "Kentucky", code: "KY", country: "United States" },
      { full: "Louisiana", code: "LA", country: "United States" },
      { full: "Maine", code: "ME", country: "United States" },
      { full: "Maryland", code: "MD", country: "United States" },
      { full: "Massachusetts", code: "MA", country: "United States" },
      { full: "Michigan", code: "MI", country: "United States" },
      { full: "Minnesota", code: "MN", country: "United States" },
      { full: "Mississippi", code: "MS", country: "United States" },
      { full: "Missouri", code: "MO", country: "United States" },
      { full: "Montana", code: "MT", country: "United States" },
      { full: "Nebraska", code: "NE", country: "United States" },
      { full: "Nevada", code: "NV", country: "United States" },
      { full: "New Hampshire", code: "NH", country: "United States" },
      { full: "New Jersey", code: "NJ", country: "United States" },
      { full: "New Mexico", code: "NM", country: "United States" },
      { full: "New York", code: "NY", country: "United States" },
      { full: "North Carolina", code: "NC", country: "United States" },
      { full: "North Dakota", code: "ND", country: "United States" },
      { full: "Ohio", code: "OH", country: "United States" },
      { full: "Oklahoma", code: "OK", country: "United States" },
      { full: "Oregon", code: "OR", country: "United States" },
      { full: "Pennsylvania", code: "PA", country: "United States" },
      { full: "Puerto Rico", code: "PR", country: "United States" },
      { full: "Rhode Island", code: "RI", country: "United States" },
      { full: "South Carolina", code: "SC", country: "United States" },
      { full: "South Dakota", code: "SD", country: "United States" },
      { full: "Tennessee", code: "TN", country: "United States" },
      { full: "Texas", code: "TX", country: "United States" },
      { full: "Utah", code: "UT", country: "United States" },
      { full: "Vermont", code: "VT", country: "United States" },
      { full: "Virgin Islands", code: "VI", country: "United States" },
      { full: "Virginia", code: "VA", country: "United States" },
      { full: "Washington", code: "WA", country: "United States" },
      { full: "West Virginia", code: "WV", country: "United States" },
      { full: "Wisconsin", code: "WI", country: "United States" },
      { full: "Wyoming", code: "WY", country: "United States" },
    ],
  },
  removeALogoText: "Delete Logo",
  deleteText: "Delete",
  resetPasswordText: "Reset Password",
  settingsText: {
    rootAdminText: "Root Admin Contact",
    sendFeedbackText: "Send Feedback",
  },
  bannerPicker: {
    minSizeRequirementForBanner:
      "An aspect ratio of 16:9 and a minimum size of 1280x720",
    imageLink:
      "The link that will be opened when this image is tapped in the Chamber Perks App™. Leave this empty if you do not want this image to be linked.",
  },
  loginInormationModal: {
    titles: {
      member: "New Member Created Successfully!",
    },
  },
  createChamberMemberText: {
    tooltip: {
      membershipStatus: {
        title:
          "Select this member's membership status. Use the information icons below for more detail about how each option effects this member perks and profile in the Chamber Perks App™. This status can be changed at any time.",
        active:
          "Choose this option if this member's membership status is active.\n\nThis option will also activate this member in the Chamber Perks App™, which means:\n* This member will be able to access their Member Dashboard to manage their profile and create new perks.\n* This member's profile will show in the Chamber Perks App™.\n* This member's perks will show in the Chamber Perks App™.",
        suspended:
          "Choose this option if this member's membership status is suspended due to unpaid dues or for any other reason.\n\nThis option will also suspend this member in the Chamber Perks App™, which means:\n* This member will not be able to access their Member Dashboard to manage their profile and create new perks.\n* This member's profile will not show in the Chamber Perks App™.\n* This member's perks will not show in the Chamber Perks App™.",
      },
      name: "Member company's name will appear to users on the Chamber Perks App™.",
      adminConsoleContactName:
        "The individual in this company who has the authority and responsibility for the company’s membership and who will receive the credentials for accessing the Chamber Perks App™ Member Dashboard.\n\nThis will not be shown in the Chamber Perks App™ and is only for internal chamber use.",
      adminConsoleLoginEmail:
        "The primary company contact email belongs to the primary company contact within your member company who will receive the member dashboard credentials to log in to their member dashboard. This individual will be able to share these credentials internally with other staff. Alternatively, to ensure a member company utilizes the Chamber Perks App™ fully, chambers should look to identify the following additional contacts listed below.\n\nThis will not be shown in the Chamber Perks App™ and is only for internal chamber use.",
      adminConsoleLoginEmailUpdateBlurb:
        "To change this, click 'Change Email Username'.",
      description:
        "Company information that will be shown on the Chamber Perks App™ to highlight your company to users. This information will also help users find your company through the Chamber Perks App™ search feature, so add as much detail as possible. You can include hours of operation, years in business, awards, and certifications, etc.\n\n(If left blank, this will not show on Chamber Perks App™)",
      contactName:
        "A company contact name that will be shown on the Chamber Perks App™ who will be responsible for company inquiries. This will allow users to connect directly with this person in your company.\n\n(If left blank, this will not show on Chamber Perks App™)",
      contactEmail:
        "The email for the above company contact that will be shown on the Chamber Perks App™, so users can easily connect. \n\n(If left blank, this will not show on Chamber Perks App™)",
      supportLink:
        "URL link of online support resource that will be shown to your member companies when they access the dashboard if they need support.",
      phoneNumber:
        "The main company phone number that will be shown on the Chamber Perks App™ for users to reach your company, or alternatively, this can be the phone number for the above company contact.\n\n(If left blank, this will not show on Chamber Perks App™)",
      website:
        "Company website URL that will be shown on the Chamber Perks App™ so users can connect directly to your company website.\n\n(If left blank, this will not show on Chamber Perks App™)",
      facebook:
        "Company Facebook Business Page URL that will be shown on the Chamber Perks App™ and will link users to your company's Facebook Business Page.\n\nHow to get this URL:\n1. Open your company's Facebook Business Page in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.facebook.com/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      instagram:
        "Company Instagram account URL that will be shown on the Chamber Perks App™ and will link users to your company's Instagram account.\n\nHow to get this URL:\n1. Open your company's Instagram account in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.instagram.com/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      threads:
        "Company Threads account URL that will be shown on the Chamber Perks App™ and will link users to your company's Threads account.\n\nHow to get this URL:\n1. Open your company's Threads account in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.threads.net/@chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      linkedin:
        "Company LinkedIn page URL that will be shown on the Chamber Perks App™ and will link users to your company's LinkedIn page.\n\nHow to get this URL:\n1. Open your company's LinkedIn page in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://linkedin.com/company/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      twitter:
        "Company Twitter account URL that will show on the Chamber Perks App™ and will link users to your company's Twitter account.\n\nHow to get this URL:\n1. Open your company's Twitter account in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://twitter.com/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      tiktok:
        "Company TikTok account URL that will be shown on the Chamber Perks App™ and will link users to your company's TikTok account.\n\nHow to get this URL:\n1. Open your company's TikTok account in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.tiktok.com/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      youtube:
        "Company Youtube channel URL that will be shown on the Chamber Perks App™ and will link users to your company's Youtube channel.\n\nHow to get this URL:\n1. Open your company's Youtube channel in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.youtube.com/@chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      streetAddress:
        "Company street address for your company’s location that will be shown on the Chamber Perks App™. If any field is left blank, your company’s location will not appear on the map feature on the Chamber Perks App™.",
      province:
        "Company province/state for your company’s location that will be shown on the Chamber Perks App™. If any field is left blank, your company’s location will not appear on the map feature on the Chamber Perks App™.",
      city: "Company city for your company’s location that will be shown on the Chamber Perks App™. If any field is left blank, your company’s location will not appear on the map feature on the Chamber Perks App™.",
      postalCode:
        "Company postal/zip code for your company’s location that will be shown on the Chamber Perks App™. If any field is left blank, your company’s location will not appear on the map feature on the Chamber Perks App™.",
      categories:
        "Select from the dropdown up to 2 of the 15 categories listed that best represent your company. Your company will be shown under the selected categories on the Chamber Perks App™.",
      logo: "Ideally a square image. Formatted as png or jpg. Max 5mb.\n\nCompany logo that will be shown on the Chamber Perks App™. If not provided, the Chamber Perks App™ will use the first letter of your company’s name as the logo.",
      directoryScreenBanner: ({
        minSizeRequirementForBanner,
      }: {
        minSizeRequirementForBanner: string;
      }) =>
        `These banners will be shown on the main directory screen in Chamber Perks App™. This screen can be opened by tapping Directory on the app's bottom navigation bar.\n\nFor best quality of both videos and images, ${
          minSizeRequirementForBanner.charAt(0).toLowerCase() +
          minSizeRequirementForBanner.slice(1)
        } are recommended.\n\nThe position of each banner in Chamber Perks App™ will be the same as shown here.\n\nClick the right and left arrows under each banner to change its positioning.`,
      profileScreenBanner: ({
        chamberName,
        minSizeRequirementForBanner,
        isChamber,
      }: {
        chamberName: string;
        minSizeRequirementForBanner: string;
        isChamber: boolean;
      }) =>
        `These banners will be shown on the ${
          isChamber ? "Chamber" : "Board"
        } profile screen in the Chamber Perks App™. This screen can be opened by tapping ${chamberName}'s logo at the top of the app's home screen.\n\nFor best quality of both videos and images, ${
          minSizeRequirementForBanner.charAt(0).toLowerCase() +
          minSizeRequirementForBanner.slice(1)
        } are recommended.\n\nThe position of each banner in Chamber Perks App™ will be the same as shown here.\n\nClick the right and left arrows under each banner to change its positioning.`,
    },
    loginWithEmailText:
      "* This will also be used as the Member Dashboard email username for your company's admin",
  },
  disocuntText: {
    perkDisabledFromBadAvailability:
      "A notification will not be sent for this perk because the current time does not match the availability day(s) and/or month(s) selected above.",
    tooltip: {
      title:
        "Create a short, catchy phrase to attract users to view and redeem this perk. More details can be specified below in description.",
      description:
        "Add specific details about this perk. This information will help users find this perk through the Chamber Perks App™ search feature, so add as much detail as possible. Additional detail options are provided below.\n\n(If left blank, this will not show on Chamber Perks App™)",
      dayToggle:
        "Enabling this option allows you to restrict this perk to only show on certain days on the Chamber Perks App™. This is a good option for controlling when the perk can be viewed and redeemed.",
      monthToggle:
        "Enabling this option allows you to restrict this perk to only show during certain months on the Chamber Perks App™. This is a good option for controlling when the perk can be viewed and redeemed.\n\nIf you select only one month, you will have the ability to set a start and expiry date for this perk during that month.",
      startAndExpiryDate:
        "This perk will only show after 12am on the start date and automatically be deleted at 11:59pm on the expiry date. Only providing a start date is not a valid input.",
      notify: (chamberName: string, created: boolean) =>
        `Enabling this feature will notify all Chamber Perks App™ users who have signed up under the ${chamberName} that your company is offering a new perk. New perk notification will only be sent when a new perk is created.`,
    },
    availableToText: "Perk Redeemable By",
    redemptionOptionText: "Perk Availability",
    redemptionOptionTooltipText: {
      title:
        "Select how often this perk can be redeemed. Use the information icons below for more detail about each option. Remember, you can set up a unique perk for each option!",
      oneTime:
        "A one time perk can be redeemed only once by a Chamber Perks App™ user. This type of perk is good for attracting first-time customers or special sales events.",
      reoccurring:
        "A reoccurring perk can be redeemed multiple times by a Chamber Perks App™ user. This type of perk is good for building repeat customers and increasing customer loyalty and sales.",
    },
    offersAvailableonCertainDaysText: "Only Offer Perk on Certain Days",
    onlyShowDuringCertainMonthsText: "Only Offer Perk During Certain Months",
    notifyText: "Send New Perk Notification",
    privateEmails: {
      title: "How to Set Up",
      mainDesc:
        "Enter the email addresses your employees used to set up an account on the Chamber Perks App™ in the text box below labelled “Employee Email List”.",
    },
    memberText: "Member Perk",
    discountUnavailableText: "No perks available please create one",
    viewsText: "Views: ",
    redemptionText: "Redemptions: ",
    offeredToText: "Redeemable By: ",
    createButtonText: "Create",
    offeredToPopup:
      "Select from the 4 options below who can view and redeem this perk on the Chamber Perks App™. For more details click the “information icon” beside each option. Remember you can always set up more than one perk.",
    generalPublicPopup:
      "By selecting this option, this perk can be viewed and redeemed by any users, using the app. This is a good way to increase your company’s exposure.",
    localChamberMembersPopup: (CHAMBER_NAME_HERE: string) =>
      `By selecting this option, you limit this perk to only be viewed and redeemed by ${CHAMBER_NAME_HERE} member companies and their employees.`,
    allChamberMembersPopup:
      "By selecting this option, this perk can only be viewed and redeemed by Chamber Perks App™ users employed by any of the participating Chambers of Commerce and Boards of Trade’s members using the app. This is a good way to increase your business exposure. (To see a list, tap the switch icon shown top right while on the home tab)",
    privatePopup:
      "By selecting this option, you are setting up a private employee perk that can only be viewed and redeemed by your employees who you allow. The employee must download the Chamber Perks App™, set up an account, and provide you with the email address they used to set up the account. More details are provided on How to Set Up once you select this option.",
    radioGroupText: {
      allChamberMembers: "All Members",
      localChamberMembers: "Local Members Only",
      generalPublic: "Anyone",
      private: "Your Company Employees Only",
    },
  },
  showInAppText: "Show in App",
  manageAChamberMemberText: {
    createDiscountText: "Create Perk",
  },
  rootAdminText: {
    amountOfDownloadsOnIOS: "Amount of Downloads on iOS",
    amountOfDownloadsOnAndroid: "Amount of Downloads on Android",
    membersInCPAText: "Total Chambers/Boards Subscribed",
    exportChamberLeads: "Export Missing City Leads",
    exportChamberMemberLeads: "Export Missing Employer Leads",
    chamberBoardLead: "City Name",
    memberCompanyLead: "Employer Name",
    creationTimeGmt: "Submission Time (GMT)",
    creationTimeLocalTZ: "Submission Time",
    viewStatBoxes: {
      chamberName: "Chamber/Board",
      numberOfUserAccounts: "Number of User Accounts",
      exportChamberLeads: "Missing City Leads",
      exportChamberMemberLeads: "Missing Employer Leads",
      membersInCPAText: "Total Chambers/Boards Subscribed",
      totalMemberCompanies: "Total Number of Member Companies",
      totalUsersSignedUp: "Total Number of User Accounts on Chamber Perks App™",
      totalPerks: "Total Number of Live Perks",
      numberOfLivePerksOfferedTo: "Number of Live Perks Redeemable By",
      chamberDetails: "Chamber/Board Details",
      perkPerMemberCategory: "Live Perks Per Member Category",
      perkPerMemberCategorySubTitle:
        "Number of live perks may seem higher than expected because one perk can belong to 2 categories",
      categoryName: "Category",
      numberOfPerks: "Live Perks",
      percentOfPerks: "% Live Perks",
      chamberPerksPerCategory: "Chamber/Board Perks Per Category",
    },
  },
  rootAdminChamberText: {
    createPerkToSeeChart: "Create a perk to see this chart",
    usersSignedUp: (chamberName: string) =>
      `Chamber Perks App™ User Accounts Under ${chamberName}`,
    usersSignedupWithText: "Total Number of Members on the Chamber Perks App™",
    numberOfBusinessesOfferingPerks: "Number of Members Offering Perks",
    numberOfTotalPerks: (chamberName: string) =>
      `Total Number of Perks Under ${chamberName}`,
    numberOfLivePerksOfferedTo: "Breakdown of Perks Offered",
    amountOfDiscountsRedeemedText: "Total number of perk redemptions",
    perksOfferedByYourMemebrs: "Perks Offered by\nYour Members",
  },
  chamberMemberAdminRootText: {
    uniqueViewsOnYourListingText: "Unique Views on Your Listing",
  },
  forgotPasswordText: {
    pleaseEnterEmailText:
      "Please enter your email username, and a code will be sent to reset your password. If you are not the owner of this email, please contact the owner to retrieve the code.",
    goBacktoLoginText: "Go Back to Login",
  },
  loginText: {
    forgotPasswordText: "Forgot Password",
  },
  newPasswordText: {
    pleaseEnterNewPasswordText: "Please enter a new password for your account",
    changePasswordText: "Change Password",
  },
  resetPassword: {
    pleaseVerifyEmailText: (email: string) =>
      `Please enter the verification code sent to ${email}, along with your new password`,
    changePasswordText: "Change Password",
    resetPasswordSuccessText:
      "Your Password has successfully been reset, please click below to return to the login page",
    returnToLoginText: "Return to Login",
  },
  createChamberText: {
    supportContactemailText:
      "* This will also be used as the Chamber Dashboard email username for the chamber's admin",
    tooltip: {
      name: "This is how your chamber/board’s name will appear to users on the Chamber Perks App™.",
      description:
        "Chamber/Board information that will be shown on the Chamber Perks App™ to highlight your chamber/board to users.",
      supportContactName:
        "A chamber/board contact name that will be shown on the Chamber Perks App™ who will be responsible for chamber/board inquiries. This will allow users to connect directly with this person in your chamber/board.",
      supportContactEmail:
        "The email for the above chamber/board contact that will be shown on the Chamber Perks App™, so users can easily connect.",
      supportLink:
        "URL link of your online support resource that will be shown to your member companies when they access the dashboard if they need support.",
      phoneNumber:
        "The main chamber/board phone number that will be shown on the Chamber Perks App™ for users to reach your chamber/board, or alternatively, this can be the phone number for the above chamber/board contact.",
      website:
        "Chamber/Board website URL that will be shown on the Chamber Perks App™ so users can connect directly to your chamber/board website.",
      facebook:
        "Chamber/Board Facebook Business Page URL that will be shown on the Chamber Perks App™ and will link users to your chamber/board's Facebook Business Page.\n\nHow to get this URL:\n1. Open your chamber/board's Facebook Business Page in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.facebook.com/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      instagram:
        "Chamber/Board Instagram account URL that will be shown on the Chamber Perks App™ and will link users to your chamber/board's Instagram account.\n\nHow to get this URL:\n1. Open your chamber/board's Instagram account in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.instagram.com/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      threads:
        "Chamber/Board Threads account URL that will be shown on the Chamber Perks App™ and will link users to your chamber/board's Threads account.\n\nHow to get this URL:\n1. Open your chamber/board's Threads account in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.threads.net/@chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      linkedin:
        "Chamber/Board LinkedIn page URL that will be shown on the Chamber Perks App™ and will link users to your chamber/board's LinkedIn page.\n\nHow to get this URL:\n1. Open your chamber/board's LinkedIn page in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://linkedin.com/chamber/company/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      twitter:
        "Chamber/Board Twitter account URL that will show on the Chamber Perks App™ and will link users to your chamber/board's Twitter account.\n\nHow to get this URL:\n1. Open your chamber/board's Twitter account in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://twitter.com/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      tiktok:
        "Chamber/Board TikTok account URL that will be shown on the Chamber Perks App™ and will link users to your chamber/board's TikTok account.\n\nHow to get this URL:\n1. Open your chamber/board's TikTok account in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.tiktok.com/chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      youtube:
        "Chamber/Board Youtube channel URL that will be shown on the Chamber Perks App™ and will link users to your chamber/board's Youtube channel.\n\nHow to get this URL:\n1. Open your chamber/board's Youtube channel in a web browser\n2. Copy the URL from the address bar of your web browser\n3. Paste it in this input field (it should look like https://www.youtube.com/@chamberperksapp)\n\n(If left blank, this will not show on Chamber Perks App™)",
      streetAddress:
        "Chamber/Board street address for your chamber/board’s location that will be shown on the Chamber Perks App™.",
      province:
        "Chamber/Board province/state for your chamber/board’s location that will be shown on the Chamber Perks App™.",
      city: "Chamber/Board city for your chamber/board’s location that will be shown on the Chamber Perks App™.",
      postalCode:
        "Chamber/Board postal/zip code for your chamber/board’s location that will be shown on the Chamber Perks App™.",
      logo: "Ideally a square image. Formatted as png or jpg. Max 5mb.\n\nChamber/Board logo that will be shown on the Chamber Perks App™. If not provided, the Chamber Perks App™ will use the first letter of your chamber/board’s name as the logo.",
      sponsorLogo: ({ isChamber }: { isChamber: boolean }) =>
        `Ideally a square image. Formatted as png or jpg. Max 5mb.\n\nThis is your sponsor's logo that will be shown in the following locations:\n* The navigation menu on your Dashboard and in each of your member's Dashboard.\n* The Directory screen on the Chamber Perks App™.\n* The ${
          isChamber ? "Chamber" : "Board"
        } Profile screen on the Chamber Perks App™.`,
      sponsorLogoLink:
        "The link that will be opened when your sponsor's logo is clicked in your Dashboard and in each of your member's Dashboard, or tapped in the Chamber Perks App™. Leave this empty if you do not want the sponsor's logo to be linked.",
      jobPostingLink:
        "Chamber/Board job board URL that will show on the Chamber Perks App™ (If left blank, this will not show on Chamber Perks App™)",
      eventsLink:
        "Chamber/Board events calendar URL that will show on the Chamber Perks App™ (If left blank, this will not show on Chamber Perks App™)",
      newsletterLink:
        "Chamber/Board newsletter URL that will show on the Chamber Perks App™ (If left blank, this will not show on Chamber Perks App™)",
      surveyLink:
        "Chamber/Board survey URL that will show on the Chamber Perks App™ (If left blank, this will not show on Chamber Perks App™)",
      memberSignupLink:
        "Chamber/Board new member signup URL that will show on the Chamber Perks App™ (If left blank, this will not show on Chamber Perks App™)",
      premiumChamberMarketer:
        "Premium chamber marketer dropbox URL (If left blank, the chamber will only be shown the basic chamber marketer in their dashboard)",
    },
  },
  loadingText: "LOADING...",
  offersPerkText: "Offers Perk",
  toastPerkText: "Perk Created",
  toastPerkUpdatedText: "Perk Updated",
  perkTitleText: "Perk Title is required",
  addressSubText:
    "If this is left empty, location will not show on map in Chamber Perks App™",
  adminConsoleLoginEmailSubText: "* Set while creating and cannot be changed",
  chamberEngage: {
    navBar: {
      categoryAdvertising: {
        popupInfo: {
          active: ({ chamberName }: { chamberName: string }) =>
            `Category advertising is active! Start adding image or video banners to elevate your company's appearance and attract more business. Contact ${chamberName} to learn more!`,
          inactive: ({ chamberName }: { chamberName: string }) =>
            `Category advertising is inactive. If you're interested in advertising in a category on the Chamber Perks App™ and uploading image or video banners to elevate your company's appearance and attract more business, contact ${chamberName} to learn more!`,
        },
      },
    },
  },
  groups: {
    [UserType.PerkPartnerDiscountAdmin]: {
      [RedirectUri.editperkPartnerDiscountPageChamberAdmin]: {
        route: RedirectUri.editperkPartnerDiscountPageChamberAdmin,
        iconName: "CPAMarketingToolkit",
        title: "Update Perk Partner",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "Update perk information that will show in Chamber Perks App™",
      },
      [RedirectUri.cpacreateperk]: {
        route: RedirectUri.cpacreateperk,
        iconName: null,
        title: "",
        showInNavBar: false,
        toolTipDescription: "",
        description: "",
      },
    },
    [UserType.RootAdmin]: {
      [RedirectUri.rootAdmin]: {
        route: RedirectUri.rootAdmin,
        iconName: "CustomStatsIcon",
        title: "View Stats",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.listAllChamberAdmin]: {
        route: RedirectUri.listAllChamberAdmin,
        iconName: "CustomManageMembersPerks",
        title: "Manage Chambers/Boards",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.updateAChamberRoute]: {
        route: RedirectUri.updateAChamberRoute,
        iconName: null,
        title: "Manage Chamber",
        showInNavbar: false,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.createAChamber]: {
        route: RedirectUri.createAChamber,
        iconName: "CustomAddIcon",
        title: "Create a Chamber/Board",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.listAllPerkPartner]: {
        route: RedirectUri.listAllPerkPartner,
        iconName: "CPAMarketingToolkit",
        title: "Manage Perk Partners",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description: "Click on a Perk Partner perk to update",
      },
      [RedirectUri.createAPerkPartner]: {
        route: RedirectUri.createAPerkPartner,
        iconName: "CustomAddIcon",
        title: "Create a Perk Partner",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.editperkPartnerDiscountPageChamberAdmin]: {
        route: RedirectUri.editperkPartnerDiscountPageChamberAdmin,
        iconName: null,
        title: "Update Perk Partner",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.cpacreateperk]: {
        route: RedirectUri.cpacreateperk,
        iconName: null,
        title: "",
        showInNavBar: false,
        toolTipDescription: "",
        description: "",
      },
      [RedirectUri.rootManageChamberEngage]: {
        route: RedirectUri.rootManageChamberEngage,
        iconName: "CustomChamberEngageIcon",
        title: "Manage Chamber Engage For All Chambers and Boards",
        useIconAsNavBarButtonTitle: true,
        showInNavBar: true,
        description:
          "Click on the pencil icon beside a value to edit Chamber Engage settings for a Chamber or Board",
      },
      [RedirectUri.chamberEngageManageCategoryAdvertisingBanners]: {
        route: RedirectUri.chamberEngageManageCategoryAdvertisingBanners,
        iconName: null,
        title: "Manage Chamber Engage™ Category Advertising Banners",
        showInNavBar: false,
        description:
          "Enable category advertising display and video banners for members.",
      },
      [RedirectUri.chamberEngageManageMemberProfileAndPerkBanners]: {
        route: RedirectUri.chamberEngageManageMemberProfileAndPerkBanners,
        iconName: null,
        title: "Manage Chamber Engage™ Enhanced Member Profile & Perks Banners",
        showInNavBar: false,
        description:
          "Enable enhanced member profile and perk display and video banners for members.",
      },
    },
    [UserType.ChamberAdmin]: {
      [RedirectUri.chamberAdmin]: {
        route: RedirectUri.chamberAdmin,
        iconName: "CustomStatsIcon",
        title: "View Stats",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "View the number of members on the Chamber Perks App™, number of perks and type of perks",
      },
      [RedirectUri.manageYourChamberChamberAdmin]: {
        route: RedirectUri.manageYourChamberChamberAdmin,
        iconName: "CustomManageChamberProfile",
        title: "Manage Your Profile",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "Update your information that will show in Chamber Perks App™",
      },
      [RedirectUri.createAMemberChamberAdmin]: {
        route: RedirectUri.createAMemberChamberAdmin,
        iconName: "CustomAddIcon",
        title: "Create New Member",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "List a member from your directory in the Chamber Perks App™ by completing as many fields as possible and clicking create.",
      },
      [RedirectUri.manageAMemberChamberAdmin]: {
        route: RedirectUri.manageAMemberChamberAdmin,
        iconName: "CustomManageMembersPerks",
        title: "Manage Members",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "Click on a member to update their information, reset their password, or delete them from your directory in the Chamber Perks App™.",
      },
      [RedirectUri.memberDiscountCreatePageChamberAdmin]: {
        route: RedirectUri.memberDiscountCreatePageChamberAdmin,
        iconName: "CustomManageCompanyPerks",
        title: "Create Member Perk",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description: "Create a perk for your member in Chamber Perks App™",
      },
      [RedirectUri.memberDiscountPageChamberAdmin]: {
        route: RedirectUri.memberDiscountPageChamberAdmin,
        iconName: "HandWithPersonIcon",
        title: "Manage Member Perks",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "Click on a perk to update or delete it from the Chamber Perks App™.",
      },
      [RedirectUri.perksLink]: {
        route: RedirectUri.perksLink,
        iconName: "PerkLinkIcon",
        title: "PerksLink™",
        showInNavBar: true,
        toolTipDescription: "",
        description: "",
      },

      [RedirectUri.chamberReward]: {
        route: RedirectUri.chamberReward,
        iconName: "RewardIcon",
        title: "Reward Campaign",
        showInNavBar: true,
        toolTipDescription: "",
        description: "",
        nestedRoutes: [
          {
            route: RedirectUri.createReward,
            title: "Create New Reward Campaign",
            showInNavBar: true,
          },
          {
            route: RedirectUri.activeChamberReward,
            title: "View Active Reward Campaign",
            showInNavBar: true,
          },
          {
            route: RedirectUri.chamberReward,
            title: "View Previous Reward Campaigns",
            showInNavBar: true,
          },
        ],
      },
      [RedirectUri.createReward]: {
        route: RedirectUri.createReward,
        iconName: null,
        title: "Create New Reward Campaign",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description: "Create a new Reward Campaign",
      },
      [RedirectUri.updateReward]: {
        route: RedirectUri.updateReward,
        iconName: null,
        title: "Update Reward Campaign",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.activeChamberReward]: {
        route: RedirectUri.activeChamberReward,
        iconName: null,
        title: "View Active Reward Campaign",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description:
          "Update, and view prize draw entrants and participating member companies for active Reward Campaign.",
      },

      [RedirectUri.chamberShopLocal]: {
        route: RedirectUri.chamberShopLocal,
        iconName: "ShopLocalIcon",
        title: "Shop Local Contest",
        showInNavBar: true,
        toolTipDescription: "",
        description: "",
        nestedRoutes: [
          {
            route: RedirectUri.createShopLocal,
            title: "Create New Shop Local Contest",
            showInNavBar: true,
          },
          {
            route: RedirectUri.activeChamberShopLocal,
            title: "View Active Shop Local Contest",
            showInNavBar: true,
          },
          {
            route: RedirectUri.chamberShopLocal,
            title: "View Previous Shop Local Contests",
            showInNavBar: true,
          },
        ],
      },
      [RedirectUri.createShopLocal]: {
        route: RedirectUri.createShopLocal,
        iconName: null,
        title: "Create New Shop Local Contest",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description: "Create a new Shop Local contest",
      },
      [RedirectUri.updateShopLocal]: {
        route: RedirectUri.updateShopLocal,
        iconName: null,
        title: "Update Shop Local Contest",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.activeChamberShopLocal]: {
        route: RedirectUri.activeChamberShopLocal,
        iconName: null,
        title: "View Active Shop Local Contest",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description:
          "Update, and view prize draw entrants and participating member companies for active Shop Local contest.",
      },
      [RedirectUri.chamberEngageManageMemberProfileAndPerkBanners]: {
        route: RedirectUri.chamberEngageManageMemberProfileAndPerkBanners,
        iconName: "CustomChamberEngageIcon",
        title: "Manage Chamber Engage™ Enhanced Member Profile & Perks Banners",
        useIconAsNavBarButtonTitle: true,
        showInNavBar: true,
        onlyShowOnActiveChamberEngage: true,
        description:
          "Enable enhanced member profile and perk display and video banners for your members.",
        nestedRoutes: [
          {
            route: RedirectUri.chamberEngageManageMemberProfileAndPerkBanners,
            title: "Manage Enhanced Member Profile & Perks Banners",
            showInNavBar: true,
            refreshOnClick: true,
          },
          {
            route: RedirectUri.chamberEngageManageCategoryAdvertisingBanners,
            title: "Manage Category Advertising Banners",
            showInNavBar: true,
            refreshOnClick: true,
          },
        ],
      },
      [RedirectUri.chamberEngageManageCategoryAdvertisingBanners]: {
        route: RedirectUri.chamberEngageManageCategoryAdvertisingBanners,
        iconName: null,
        title: "Manage Chamber Engage™ Category Advertising Banners",
        showInNavBar: false,
        description:
          "Enable category advertising display and video banners for your members.",
      },
      [RedirectUri.cpaMarketingToolkit]: {
        route: RedirectUri.cpaMarketingToolkit,
        iconName: "CustomChamberMarketerLogo",
        title: "Chamber Marketer",
        useIconAsNavBarButtonTitle: true,
        showInNavBar: true,
        description:
          "Everything you will need to successfully launch and educate your members on utilizing the Chamber Perks App™. Content will be updated regularly.",
      },
      [RedirectUri.importAMemberChamberAdmin]: {
        route: RedirectUri.importAMemberChamberAdmin,
        iconName: "CustomAddIcon",
        title: "Import Members",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.chamberAdminManualLaunchGuide]: {
        route: RedirectUri.chamberAdminManualLaunchGuide,
        iconName: "CustomManualLaunchGuideLogo",
        title: "Manual & Launch Guide",
        showInNavBar: true,
        useIconAsNavBarButtonTitle: true,
        description: "Chamber Perks App™ Manual & Launch guide",
      },
      [RedirectUri.settingsOfChamberChamberAdmin]: {
        route: RedirectUri.settingsOfChamberChamberAdmin,
        iconName: "CustomSupport",
        title: "Help",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "Contact information for dashboard assistnace, feedback, and technical support",
      },
      [RedirectUri.editDiscountPageChamberAdmin]: {
        route: RedirectUri.editDiscountPageChamberAdmin,
        iconName: null,
        title: "Update Perk",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description:
          "Update perk information that will show in Chamber Perks App™",
      },
      [RedirectUri.updateAMemberChamberAdmin]: {
        route: RedirectUri.updateAMemberChamberAdmin,
        iconName: null,
        title: "Manage Member",
        showInNavBar: false,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.cpacreateperk]: {
        route: RedirectUri.cpacreateperk,
        iconName: null,
        title: "",
        showInNavBar: false,
        toolTipDescription: "",
        description: "",
      },
    },
    [UserType.ChamberMemberAdmin]: {
      [RedirectUri.updateAMemberChamberAdmin]: {
        route: RedirectUri.updateAMemberChamberAdmin,
        iconName: "CustomManageCompanyProfile",
        title: "Manage Company Profile",
        showEnhancedBannersPill: true,
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "Update your company information that will show in Chamber Perks App™",
      },
      [RedirectUri.manageDiscountChamberMemberAdmin]: {
        route: RedirectUri.manageDiscountChamberMemberAdmin,
        iconName: "CustomManageCompanyPerks",
        title: "Manage Company Perks",
        showEnhancedBannersPill: true,
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "Update, delete, and see the number of views of your company's perks",
      },
      [RedirectUri.createDiscountChamberMemberAdmin]: {
        route: RedirectUri.createDiscountChamberMemberAdmin,
        iconName: "CustomAddIcon",
        title: "Create a Perk",
        showEnhancedBannersPill: true,
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description: "Create your company perk on the Chamber Perks App™",
      },
      [RedirectUri.editDiscountChamberMemberAdmin]: {
        route: RedirectUri.editDiscountChamberMemberAdmin,
        iconName: null,
        title: "Edit your Perk",
        showInNavBar: null,
        toolTipDescription: "lorem ipsum",
        description: "",
      },
      [RedirectUri.chamberEngageManageCategoryAdvertisingBannersChamberMember]:
        {
          route:
            RedirectUri.chamberEngageManageCategoryAdvertisingBannersChamberMember,
          iconName:
            "ChamberEngageManageCategoryAdvertisingBannersChamberMemberIcon",
          title: "Manage Category Advertising Banners",
          showEnhancedBannersPill: true,
          showInNavBar: true,
          toolTipDescription: "lorem ipsum",
          description: "",
          refreshOnClick: true,
        },
      [RedirectUri.chamberMemberShopLocal]: {
        route: RedirectUri.chamberMemberShopLocal,
        iconName: "ShopLocalIcon",
        title: "Shop Local Contest",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description: "See active Shop Local contest",
      },
      [RedirectUri.cpaMarketingToolkit]: {
        route: RedirectUri.cpaMarketingToolkit,
        iconName: "CPAMarketingToolkit",
        title: "Marketing Toolkit",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "Access marketing material to help promote your company perks. Includes social media posts, website banners and flyers. Content will be updated regularly.",
      },
      [RedirectUri.settingsChamberMemberAdmin]: {
        route: RedirectUri.settingsChamberMemberAdmin,
        iconName: "CustomSupport",
        title: "Help",
        showInNavBar: true,
        toolTipDescription: "lorem ipsum",
        description:
          "Contact information for dashboard assistnace, feedback, and technical support",
      },
      [RedirectUri.cpacreateperk]: {
        route: RedirectUri.cpacreateperk,
        iconName: null,
        title: "",
        showInNavBar: false,
        toolTipDescription: "",
        description: "",
      },
    },
  },
};

export default enumStrings;
