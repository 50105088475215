import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  ChamberMemberCategory,
  ChamberUserDataReturn,
  DiscountReturn,
  GetAllDeletedDiscountsFromChamberIdDocument,
  GetAllDeletedDiscountsFromChamberIdQuery,
  GetAllDiscountsFromChamberIdDocument,
  GetAllDiscountsFromChamberIdQuery,
  useGetAllCategoriesQuery,
  useGetAllChamberMemberUsersQuery,
  useGetAllChambersQuery,
  useGetAllChamberUsersQuery,
  useGetAllContactableChambersQuery,
  useGetAllContactableCompaniesQuery,
  UserType,
} from "../../../graphql/types";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import Loader from "../../common/Loader/Loader";
import ExportButton from "../../common/ExportButton";
import { getApolloContext } from "@apollo/client";
import ViewStatsBoxes from "../../common/ViewStatsBoxes";
import { useMemo } from "react";
import { getOfferedToText } from "../ChamberAdmin/RootChamberAdmin";
import { DateTime } from "luxon";
import RootAdminStatTable, { DeltaType } from "../../common/RootAdminStatTable";

const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  contentContainer: {
    display: "flex",
    height: "80vh",
    flexWrap: "wrap",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
}));

export const getLocalTimestamp = (
  utcMilliseconds: number,
  removeHours?: boolean
) => {
  if (utcMilliseconds === 0) {
    return "";
  }

  // Create a Luxon DateTime object from UTC milliseconds
  const utcDateTime = DateTime.fromMillis(utcMilliseconds, { zone: "utc" });

  // Convert to the local time zone
  const localDateTime = utcDateTime.toLocal();

  // Format the local date and time
  const formattedDate = localDateTime.toFormat(
    removeHours ? "LLL dd, yyyy" : "LLL dd, yyyy, hh:mm a"
  );

  return formattedDate;
};

function parseTimestamp(timestamp: string) {
  const date = new Date(timestamp);

  if (!isNaN(date.getTime())) {
    // If the date is valid, convert it to UTC milliseconds
    return date.getTime();
  }

  return null; // Return null for invalid timestamps
}

const RootAdmin = (): ReactElement => {
  const classes = useStyles();
  const rootAdminObj =
    enumStrings.groups[UserType.RootAdmin][RedirectUri.rootAdmin];

  const { data } = useGetAllChambersQuery({ fetchPolicy: "cache-and-network" });

  const { data: contactableChambers, loading: contactableChambersLoading } =
    useGetAllContactableChambersQuery({
      fetchPolicy: "cache-and-network",
    });

  let csvContactableChamberLabel = [
    { label: enumStrings.rootAdminText.chamberBoardLead, key: "chamberName" },
    {
      label: enumStrings.rootAdminText.creationTimeGmt,
      key: "creationTimeUtc",
    },
  ];

  let csvContactableChamberData = contactableChambers?.getAllContactableChambers
    ?.map((item: any) => ({
      chamberName: item.name,
      creationTimeUtc: item.createdAtUtcMilli
        ? new Date(item.createdAtUtcMilli).toUTCString()
        : "",
      createdAtUtcMilli: item.createdAtUtcMilli || 0,
    }))
    .sort(function (a, b) {
      return b.createdAtUtcMilli - a.createdAtUtcMilli;
    });

  const { data: contactableComapnies, loading: contactableComapniesLoading } =
    useGetAllContactableCompaniesQuery({
      fetchPolicy: "cache-and-network",
    });

  let csvContactableCompaniesLabel = [
    { label: "Chamber/Board Name", key: "chamberName" },
    { label: enumStrings.rootAdminText.memberCompanyLead, key: "name" },
    {
      label: enumStrings.rootAdminText.creationTimeGmt,
      key: "creationTimeUtc",
    },
  ];

  let csvContactableComapniesData =
    contactableComapnies?.getAllContactableCompanies
      ?.map((item: any) => ({
        chamberName: item.chamberName,
        name: item.name,
        creationTimeUtc: item.createdAtUtcMilli
          ? new Date(item.createdAtUtcMilli).toUTCString()
          : "",
        createdAtUtcMilli: item.createdAtUtcMilli || 0,
      }))
      .sort(function (a, b) {
        return b.createdAtUtcMilli - a.createdAtUtcMilli;
      });

  let { data: chamberUsers, loading: chamberUsersLoading } =
    useGetAllChamberUsersQuery({
      fetchPolicy: "cache-and-network",
    });

  let exportChamberUsersCsvListLabel = [
    { label: "Chamber/Board Name", key: "chamberName" },
    {
      label: "Chamber Perks App Users Signed Up Under Chamber",
      key: "numberOfUsers",
    },
  ];

  let exportChamberUsersCsvListData = chamberUsers?.getAllChamberUsers
    ?.map((item: ChamberUserDataReturn) => ({
      chamberName: item.chamberName,
      numberOfUsers: item.numberOfUsers,
      numberOfUsersWithoutAnyEmployerSelected:
        item.numberOfUsersWithoutAnyEmployerSelected,
    }))
    .sort(function (a, b) {
      return b.numberOfUsers - a.numberOfUsers;
    });

  const totalNumberOfUsersSignedUpOnChamberPerksApp = useMemo(() => {
    let users = 0;

    for (const data of exportChamberUsersCsvListData || []) {
      users += data.numberOfUsers;
    }

    return users;
  }, [exportChamberUsersCsvListData]);

  const apolloClient = useContext(getApolloContext());

  let { data: allChamberData, loading: allChamberDataLoading } =
    useGetAllChambersQuery({
      fetchPolicy: "cache-and-network",
    });

  const [discountDataLoading, setDiscountDataLoading] = useState(true);
  const [discountData, setDiscountData] = useState<
    DiscountReturn[] | undefined
  >(undefined);
  const [nonDeletedDiscountData, setNonDeletedDiscountData] = useState<
    DiscountReturn[] | undefined
  >(undefined);

  const getDiscountData = useCallback(async () => {
    if (
      allChamberData &&
      allChamberData.getAllChambers &&
      !allChamberDataLoading
    ) {
      let returnData: DiscountReturn[] = [];
      let nonDeletedData: DiscountReturn[] = [];
      for (const chamber of allChamberData.getAllChambers) {
        const discountData = (await apolloClient.client?.query({
          query: GetAllDiscountsFromChamberIdDocument,
          variables: {
            chamberId: chamber.id,
          },
          fetchPolicy: "cache-first",
        })) as { data: GetAllDiscountsFromChamberIdQuery | undefined };

        const deletedDiscountData = (await apolloClient.client?.query({
          query: GetAllDeletedDiscountsFromChamberIdDocument,
          variables: {
            chamberId: chamber.id,
          },
          fetchPolicy: "cache-first",
        })) as { data: GetAllDeletedDiscountsFromChamberIdQuery | undefined };

        returnData = [
          ...returnData,
          ...(discountData?.data?.getAllDiscountsFromChamberId || ([] as any)),
          ...(deletedDiscountData?.data?.getAllDeletedDiscountsFromChamberId ||
            ([] as any)),
        ];
        nonDeletedData = [
          ...nonDeletedData,
          ...(discountData?.data?.getAllDiscountsFromChamberId || ([] as any)),
        ];
      }
      setDiscountDataLoading(false);
      setDiscountData(returnData);
      setNonDeletedDiscountData(nonDeletedData);
    }
  }, [allChamberData, allChamberDataLoading, apolloClient.client]);

  let { data: chamberMemberUsers, loading: chamberMemberUsersLoading } =
    useGetAllChamberMemberUsersQuery({
      fetchPolicy: "cache-and-network",
    });

  const { data: categoriesData } = useGetAllCategoriesQuery();

  let exportChamberMemberUsersCsvListLabel = [
    { label: "Chamber/Board Name", key: "chamberName" },
    { label: "Member Name", key: "chamberMemberName" },
    {
      label: "Membership Status",
      key: "membershipStatus",
    },
    { label: "Category 1", key: "category1" },
    { label: "Category 2", key: "category2" },
    {
      label: "Chamber Perks App Users Signed Up Under Member",
      key: "numberOfUsers",
    },
    { label: "Number of Perks Offered", key: "numberOfPerksOffered" },
    { label: "Has Logged In To Dashboard", key: "hasLoggedIn" },
    { label: "Has Logo", key: "hasLogo" },
    { label: "Creation Time (GMT)", key: "creationTimeUtc" },
  ];

  const exportChamberMemberUsersCsvListData = useMemo(() => {
    const memberIdToNumberOfPerksOffered: any = {};

    for (const discountForChamber of nonDeletedDiscountData || []) {
      if (
        !(discountForChamber?.chamberMemberId in memberIdToNumberOfPerksOffered)
      ) {
        memberIdToNumberOfPerksOffered[discountForChamber?.chamberMemberId] = 0;
      }

      memberIdToNumberOfPerksOffered[discountForChamber?.chamberMemberId] += 1;
    }

    return chamberMemberUsers?.getAllChamberMemberUsers
      ?.map((item: any) => ({
        chamberName: item.chamberName,
        chamberMemberName: item.chamberMemberName,
        numberOfUsers: item.numberOfUsers,
        createdAtUtcMilli: item.createdAtUtcMilli,
        numberOfInitialMembers:
          item.numberOfMembersFromInitialSubscriptionAgreement,
        hasLoggedIn: item.hasLoggedIn ? "Yes" : "No",
        hasLogo: (item?.logo || "").length > 0 ? "Yes" : "No",
        numberOfPerksOffered:
          memberIdToNumberOfPerksOffered[item.chamberMemberId] || 0,
        category1:
          categoriesData?.getAllCategories?.find(
            ({ id }) => id === item.chamberMemberCategoryId[0]
          )?.name || "",
        category2: item.chamberMemberCategoryId[1]
          ? categoriesData?.getAllCategories?.find(
              ({ id }) => id === item.chamberMemberCategoryId[1]
            )?.name || ""
          : "",
        creationTimeUtc: item.createdAtUtcMilli
          ? new Date(item.createdAtUtcMilli).toUTCString()
          : "",
      }))
      .sort(function (a, b) {
        if (a.chamberName === b.chamberName) {
          return b.hasLoggedIn.localeCompare(a.hasLoggedIn);
        }
        return a.chamberName.localeCompare(b.chamberName);
      });
  }, [
    categoriesData?.getAllCategories,
    chamberMemberUsers?.getAllChamberMemberUsers,
    nonDeletedDiscountData,
  ]);

  const newestMembers = useMemo(() => {
    return (exportChamberMemberUsersCsvListData || [])
      .sort((a, b) => b.createdAtUtcMilli - a.createdAtUtcMilli)
      .slice(0, 100);
  }, [exportChamberMemberUsersCsvListData]);

  const {
    totalNumberOfUsersSignedUpWithAnEmployer,
    totalNumberOfUsersSignedUpWithoutAnEmployer,
  } = useMemo(() => {
    let totalNumberOfUsersSignedUpWithAnEmployer = 0;

    for (const member of exportChamberMemberUsersCsvListData || []) {
      totalNumberOfUsersSignedUpWithAnEmployer += member.numberOfUsers;
    }

    return {
      totalNumberOfUsersSignedUpWithAnEmployer,
      totalNumberOfUsersSignedUpWithoutAnEmployer:
        totalNumberOfUsersSignedUpOnChamberPerksApp -
        totalNumberOfUsersSignedUpWithAnEmployer,
    };
  }, [
    exportChamberMemberUsersCsvListData,
    totalNumberOfUsersSignedUpOnChamberPerksApp,
  ]);

  useEffect(() => {
    getDiscountData();
  }, [getDiscountData]);

  let exportPerksCsvDiscountListLabel = [
    { label: "Chamber/Board Name", key: "chamberName" },
    { label: "Member Name", key: "memberName" },
    { label: "Category 1", key: "category1" },
    { label: "Category 2", key: "category2" },
    { label: "Perk Name", key: "discountName" },
    { label: "Redeemable By", key: "offeredTo" },
    { label: "Perk Views", key: "discountViews" },
    { label: "Perk Redemptions", key: "discountRedemptions" },
    {
      label: enumStrings.rootAdminText.creationTimeGmt,
      key: "creationTimeUtc",
    },
    { label: "Deletion Time (GMT)", key: "deletionTimeUtc" },
  ];

  let exportPerksCsvDiscountListData = (discountData || [])
    ?.map((item: any) => ({
      chamberName: item.chamberInfo.name,
      memberName: item.chamberMemberInfo.name,
      discountName: item.title,
      offeredTo: getOfferedToText(item),
      discountViews: item.views || 0,
      discountRedemptions: item.numberOfRedemptions || 0,
      creationTimeUtc: item.createdAtUtcMilli
        ? new Date(item.createdAtUtcMilli).toUTCString()
        : "",
      deletionTimeUtc: item.deletedAtUtcMilli
        ? new Date(item.deletedAtUtcMilli).toUTCString()
        : "",
      createdAtUtcMilli: item.createdAtUtcMilli || 0,
      deletedAtUtcMilli: item.deletedAtUtcMilli || 0,
      category1:
        categoriesData?.getAllCategories?.find(
          ({ id }) => id === item.chamberMemberInfo.categoryId[0]
        )?.name || "",
      category2: item.chamberMemberInfo.categoryId[1]
        ? categoriesData?.getAllCategories?.find(
            ({ id }) => id === item.chamberMemberInfo.categoryId[1]
          )?.name || ""
        : "",
    }))
    .sort(function (a, b) {
      return b.createdAtUtcMilli - a.createdAtUtcMilli;
    })
    .sort((a, b) => {
      if (a.chamberName < b.chamberName) {
        return -1;
      } else if (a.chamberName > b.chamberName) {
        return 1;
      } else {
        return 0;
      }
    });

  const newestPerks = useMemo(() => {
    return (exportPerksCsvDiscountListData || [])
      .sort((a, b) => b.createdAtUtcMilli - a.createdAtUtcMilli)
      .slice(0, 100);
  }, [exportPerksCsvDiscountListData]);

  let totalDiscountViews = 0;
  let totalDiscountRedemptions = 0;

  for (const discount of exportPerksCsvDiscountListData || []) {
    totalDiscountViews += discount.discountViews;
    totalDiscountRedemptions += discount.discountRedemptions;
  }

  const totalDataArr = [
    {
      chamberName: "TOTAL VIEWS AND REDEMPTIONS ACROSS ALL PERKS",
      memberName: "TOTAL VIEWS AND REDEMPTIONS ACROSS ALL PERKS",
      discountName: "TOTAL VIEWS AND REDEMPTIONS ACROSS ALL PERKS",
      offeredTo: "",
      discountViews: totalDiscountViews,
      discountRedemptions: totalDiscountRedemptions,
      creationTimeUtc: "",
      deletionTimeUtc: "",
      createdAtUtcMilli: 0,
      deletedAtUtcMilli: 0,
      category1: "",
      category2: "",
    },
  ];

  exportPerksCsvDiscountListData = [
    ...totalDataArr,
    ...exportPerksCsvDiscountListData,
  ];

  const totalNumberOfLivePerks = useMemo(() => {
    return nonDeletedDiscountData?.length;
  }, [nonDeletedDiscountData]);

  const numberOfCompaniesOfferingLivePerks = useMemo(() => {
    const perkCompanies: Array<string> = [];

    for (const csvPerkData of nonDeletedDiscountData || []) {
      if (!perkCompanies.includes(csvPerkData.chamberMemberId)) {
        perkCompanies.push(csvPerkData.chamberMemberId);
      }
    }

    return perkCompanies.length;
  }, [nonDeletedDiscountData]);

  const percentOfCompaniesOfferingLivePerks = useMemo(() => {
    const numOfChamberMembers = (exportChamberMemberUsersCsvListData || [])
      .length;

    return `${Math.round(
      (numberOfCompaniesOfferingLivePerks / numOfChamberMembers) * 100
    ).toFixed(0)}%`;
  }, [exportChamberMemberUsersCsvListData, numberOfCompaniesOfferingLivePerks]);

  const {
    numOfAllMemberPerks,
    numOfLocalMemberOnlyPerks,
    numOfGeneralPublicPerks,
    numOfCompanyEmployeesOnlyPerks,
  } = useMemo(() => {
    let numOfAllMemberPerks = 0;
    let numOfLocalMemberOnlyPerks = 0;
    let numOfGeneralPublicPerks = 0;
    let numOfCompanyEmployeesOnlyPerks = 0;

    const businessesOfferingPerksIds = [];

    for (const discount of nonDeletedDiscountData || []) {
      businessesOfferingPerksIds.push(discount.chamberMemberInfo.id);

      if (discount.isPrivate) {
        numOfCompanyEmployeesOnlyPerks += 1;
      } else if (!discount.memberOnly) {
        numOfGeneralPublicPerks += 1;
      } else if (discount.memberOnly && !discount.isAvailableToAllMembers) {
        numOfLocalMemberOnlyPerks += 1;
      } else if (discount.memberOnly && discount.isAvailableToAllMembers) {
        numOfAllMemberPerks += 1;
      }
    }

    return {
      numOfAllMemberPerks,
      numOfLocalMemberOnlyPerks,
      numOfGeneralPublicPerks,
      numOfCompanyEmployeesOnlyPerks,
    };
  }, [nonDeletedDiscountData]);

  const livePerksPerMemberCategoryBreakdown = useMemo(() => {
    if (!categoriesData?.getAllCategories || !nonDeletedDiscountData) {
      return [];
    }

    const livePerksPerMemberCategory: {
      [k: string]: {
        livePerks: number;
        rawPercentageNumber: number;
        categoryId: ChamberMemberCategory;
        categoryName: string;
      };
    } = {};

    for (const perk of nonDeletedDiscountData || []) {
      const perksChamberMembersCategory = perk.chamberMemberInfo.categoryId;
      for (const catId of perksChamberMembersCategory) {
        if (!(catId in livePerksPerMemberCategory)) {
          livePerksPerMemberCategory[catId] = {
            livePerks: 0,
            rawPercentageNumber: 0,
            categoryId: catId,
            categoryName:
              categoriesData?.getAllCategories.find(({ id }) => id === catId)
                ?.name || "",
          };
        }
        livePerksPerMemberCategory[catId].livePerks += 1;
      }
    }
    for (const catId in livePerksPerMemberCategory) {
      livePerksPerMemberCategory[catId].rawPercentageNumber = Math.round(
        (livePerksPerMemberCategory[catId].livePerks /
          (totalNumberOfLivePerks || 1)) *
          100
      );
    }

    for (const cat of categoriesData.getAllCategories || []) {
      if (!(cat.id in livePerksPerMemberCategory)) {
        livePerksPerMemberCategory[cat.id] = {
          categoryId: cat.id,
          categoryName: cat.name,
          livePerks: 0,
          rawPercentageNumber: 0,
        };
      }
    }

    // Convert the object into an array of key-value pairs
    const entries = Object.values(livePerksPerMemberCategory);

    // Sort the array based on the "bingo" property in descending order
    return entries.sort((a, b) => b.livePerks - a.livePerks);
  }, [
    categoriesData?.getAllCategories,
    nonDeletedDiscountData,
    totalNumberOfLivePerks,
  ]);

  const chamberPerksPerCategoryBreakdown = useMemo(() => {
    if (!categoriesData?.getAllCategories || !nonDeletedDiscountData) {
      return [];
    }

    const chamberPerksPerCategory: {
      [chamberName: string]: {
        [catId: string]: {
          chamberName: string;
          livePerks: number;
          categoryId: ChamberMemberCategory;
          categoryName: string;
        };
      };
    } = {};

    for (const perk of nonDeletedDiscountData || []) {
      const perksChamberName = perk.chamberInfo.name;
      if (!(perksChamberName in chamberPerksPerCategory)) {
        chamberPerksPerCategory[perksChamberName] = {};
      }

      const perksChamberMembersCategory = perk.chamberMemberInfo.categoryId;
      for (const catId of perksChamberMembersCategory) {
        if (!(catId in chamberPerksPerCategory[perksChamberName])) {
          chamberPerksPerCategory[perksChamberName][catId] = {
            chamberName: perksChamberName,
            livePerks: 0,
            categoryId: catId,
            categoryName:
              categoriesData?.getAllCategories.find(({ id }) => id === catId)
                ?.name || "",
          };
        }
        chamberPerksPerCategory[perksChamberName][catId].livePerks += 1;
      }
    }

    for (const cat of categoriesData.getAllCategories || []) {
      for (const chamberName in chamberPerksPerCategory) {
        if (!(cat.id in chamberPerksPerCategory[chamberName])) {
          chamberPerksPerCategory[chamberName][cat.id] = {
            chamberName: chamberName,
            livePerks: 0,
            categoryId: cat.id,
            categoryName: cat.name,
          };
        }
      }
    }

    const returnArr = [];
    for (const chamberName in chamberPerksPerCategory) {
      const chamberObj = {
        chamberName,
      };

      for (const categoryId in chamberPerksPerCategory[chamberName]) {
        // @ts-ignore
        chamberObj[categoryId] =
          chamberPerksPerCategory[chamberName][categoryId].livePerks;
      }

      returnArr.push(chamberObj);
    }
    return returnArr;
  }, [categoriesData?.getAllCategories, nonDeletedDiscountData]);

  const chamberPerkOfferBreakdown = useMemo(() => {
    const chamberToNumberOfMembers: { [k: string]: number } = {};
    const chamberToListOfMembersOfferingPerks: {
      [k: string]: Array<string>;
    } = {};
    const chamberToNumberOfPerks: { [k: string]: number } = {};
    const chamberToNumberOfPerkViews: { [k: string]: number } = {};
    const chamberToNumberOfPerkRedemptions: { [k: string]: number } = {};
    const chamberToNumberOfMembersWhoHaveLoggedInToAdminPortal: {
      [k: string]: number;
    } = {};
    const chamberToNumberOfAppUsersWithEmployerSelected: {
      [k: string]: number;
    } = {};

    for (const member of exportChamberMemberUsersCsvListData || []) {
      if (!(member.chamberName in chamberToNumberOfMembers)) {
        chamberToNumberOfMembers[member.chamberName] = 0;
      }
      chamberToNumberOfMembers[member.chamberName] += 1;

      if (
        !(
          member.chamberName in
          chamberToNumberOfMembersWhoHaveLoggedInToAdminPortal
        )
      ) {
        chamberToNumberOfMembersWhoHaveLoggedInToAdminPortal[
          member.chamberName
        ] = 0;
      }
      if (member.hasLoggedIn === "Yes") {
        chamberToNumberOfMembersWhoHaveLoggedInToAdminPortal[
          member.chamberName
        ] += 1;
      }

      if (
        !(member.chamberName in chamberToNumberOfAppUsersWithEmployerSelected)
      ) {
        chamberToNumberOfAppUsersWithEmployerSelected[member.chamberName] = 0;
      }
      chamberToNumberOfAppUsersWithEmployerSelected[member.chamberName] +=
        member.numberOfUsers;
    }

    for (const perk of nonDeletedDiscountData || []) {
      if (!(perk.chamberInfo.name in chamberToNumberOfPerks)) {
        chamberToNumberOfPerks[perk.chamberInfo.name] = 0;
      }
      chamberToNumberOfPerks[perk.chamberInfo.name] += 1;

      if (!(perk.chamberInfo.name in chamberToListOfMembersOfferingPerks)) {
        chamberToListOfMembersOfferingPerks[perk.chamberInfo.name] = [];
      }
      if (
        !chamberToListOfMembersOfferingPerks[perk.chamberInfo.name].includes(
          perk.chamberMemberInfo.name
        )
      ) {
        chamberToListOfMembersOfferingPerks[perk.chamberInfo.name].push(
          perk.chamberMemberInfo.name
        );
      }

      if (!(perk.chamberInfo.name in chamberToNumberOfPerkViews)) {
        chamberToNumberOfPerkViews[perk.chamberInfo.name] = 0;
      }
      chamberToNumberOfPerkViews[perk.chamberInfo.name] += perk.views || 0;

      if (!(perk.chamberInfo.name in chamberToNumberOfPerkRedemptions)) {
        chamberToNumberOfPerkRedemptions[perk.chamberInfo.name] = 0;
      }
      chamberToNumberOfPerkRedemptions[perk.chamberInfo.name] +=
        perk.numberOfRedemptions || 0;
    }

    const chamberToNumberOfInitialMembers: {
      [k: string]: number | undefined | null;
    } = {};
    const chamberToSubscriptionDate: {
      [k: string]: string | undefined | null;
    } = {};
    if (
      allChamberData &&
      allChamberData.getAllChambers &&
      !allChamberDataLoading
    ) {
      for (const chamber of allChamberData.getAllChambers) {
        if (!(chamber.name in chamberToNumberOfInitialMembers)) {
          chamberToNumberOfInitialMembers[chamber.name] =
            chamber.numberOfMembersFromInitialSubscriptionAgreement;
        }

        if (!(chamber.name in chamberToSubscriptionDate)) {
          chamberToSubscriptionDate[chamber.name] = chamber.subscriptionDate;
        }
      }
    }

    const chamberToNumberOfAppUsers: {
      [k: string]: number;
    } = {};
    const chamberToNumberOfAppUsersWithoutAnyEmployerSelected: {
      [k: string]: number;
    } = {};
    for (const chamber of exportChamberUsersCsvListData || []) {
      if (!(chamber.chamberName in chamberToNumberOfAppUsers)) {
        chamberToNumberOfAppUsers[chamber.chamberName] = chamber.numberOfUsers;
        chamberToNumberOfAppUsersWithoutAnyEmployerSelected[
          chamber.chamberName
        ] = chamber.numberOfUsersWithoutAnyEmployerSelected;
      }
    }

    const res = [];

    for (const chamberName in chamberToNumberOfMembers) {
      const numOfInitialMemberCompanies =
        chamberToNumberOfInitialMembers[chamberName] || 0;
      const numOfMemberCompanies = chamberToNumberOfMembers[chamberName] || 0;
      const numOfLivePerks = chamberToNumberOfPerks[chamberName] || 0;
      const numberOfPerkViews = chamberToNumberOfPerkViews[chamberName] || 0;
      const numberOfPerkRedemptions =
        chamberToNumberOfPerkRedemptions[chamberName] || 0;
      const numOfCompaniesOfferingLivePerks = (
        chamberToListOfMembersOfferingPerks[chamberName] || []
      ).length;
      const numOfCompaniesLoggedInToAdminPortal =
        chamberToNumberOfMembersWhoHaveLoggedInToAdminPortal[chamberName] || 0;
      const subscriptionTimestamp = chamberToSubscriptionDate[chamberName];
      const numberOfAppUsers = chamberToNumberOfAppUsers[chamberName] || 0;
      const numberOfAppUsersWithEmployerSelected =
        chamberToNumberOfAppUsersWithEmployerSelected[chamberName] || 0;
      const numberOfAppUsersWithoutEmployerSelected =
        chamberToNumberOfAppUsersWithoutAnyEmployerSelected[chamberName] || 0;

      res.push({
        chamberName,
        numOfInitialMemberCompanies: numOfInitialMemberCompanies,
        numOfMemberCompanies: numOfMemberCompanies,
        numOfLivePerks: numOfLivePerks,
        numberOfPerkViews: numberOfPerkViews,
        numberOfPerkRedemptions: numberOfPerkRedemptions,
        numberOfAppUsers: numberOfAppUsers,
        numberOfAppUsersWithEmployerSelected:
          numberOfAppUsersWithEmployerSelected,
        numberOfAppUsersWithoutEmployerSelected:
          numberOfAppUsersWithoutEmployerSelected,
        numOfCompaniesOfferingLivePerks: numOfCompaniesOfferingLivePerks,
        percentOfCompaniesOfferingLivePerks: Math.round(
          (numOfCompaniesOfferingLivePerks / numOfMemberCompanies) * 100
        ),
        numOfCompaniesLoggedInToAdminPortal:
          numOfCompaniesLoggedInToAdminPortal,
        percentOfCompaniesLoggedInToAdminPortal: Math.round(
          (numOfCompaniesLoggedInToAdminPortal / numOfMemberCompanies) * 100
        ),
        subscriptionTimestamp: subscriptionTimestamp,
        subscriptionUTCMilli: parseTimestamp(subscriptionTimestamp || ""),
        numOfMemberCompanyDelta:
          numOfMemberCompanies - numOfInitialMemberCompanies,
        percentOfMemberCompanyDelta: Math.round(
          ((numOfMemberCompanies - numOfInitialMemberCompanies) /
            numOfInitialMemberCompanies) *
            100
        ),
        numOfMemberCompanyDeltaType:
          numOfMemberCompanies - numOfInitialMemberCompanies > 0
            ? DeltaType.INCREASE
            : DeltaType.DECREASE,
      });
    }

    if (!nonDeletedDiscountData || nonDeletedDiscountData.length === 0) {
      return [];
    }

    return res;
  }, [
    allChamberData,
    allChamberDataLoading,
    exportChamberMemberUsersCsvListData,
    exportChamberUsersCsvListData,
    nonDeletedDiscountData,
  ]);
  console.log(JSON.stringify(chamberPerkOfferBreakdown));

  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <PositionedTooltips
            //  @ts-ignore
            title={rootAdminObj?.title}
            //  @ts-ignore
            description={rootAdminObj?.toolTipDescription}
          />{" "}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <ExportButton
            loading={chamberUsersLoading}
            csvData={exportChamberUsersCsvListData}
            csvHeaders={exportChamberUsersCsvListLabel}
            label="Export Number of Users Per Chamber/Board"
          />
          <ExportButton
            loading={chamberMemberUsersLoading || discountDataLoading}
            csvData={exportChamberMemberUsersCsvListData}
            csvHeaders={exportChamberMemberUsersCsvListLabel}
            label="Export Members"
          />
          <ExportButton
            loading={discountDataLoading}
            csvData={exportPerksCsvDiscountListData}
            csvHeaders={exportPerksCsvDiscountListLabel}
            label="Export All Perks"
          />
          <ExportButton
            loading={contactableChambersLoading}
            csvData={csvContactableChamberData}
            csvHeaders={csvContactableChamberLabel}
            label={enumStrings.rootAdminText.exportChamberLeads}
          />
          <ExportButton
            loading={contactableComapniesLoading}
            csvData={csvContactableComapniesData}
            csvHeaders={csvContactableCompaniesLabel}
            label={enumStrings.rootAdminText.exportChamberMemberLeads}
          />
        </div>
      </div>

      <ViewStatsBoxes
        boxes={[
          {
            fullWidth: true,
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.totalUsersSignedUp,
            boxContent: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!exportChamberUsersCsvListData ||
                !exportChamberMemberUsersCsvListData ? (
                  <Loader />
                ) : (
                  <>
                    {totalNumberOfUsersSignedUpOnChamberPerksApp}
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "5px",
                        padding: "30px",
                        paddingTop: "0",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          With an employer selected:{" "}
                          <span
                            style={{
                              color: "#E1A731",
                            }}
                          >
                            {totalNumberOfUsersSignedUpWithAnEmployer} (
                            {Math.round(
                              (totalNumberOfUsersSignedUpWithAnEmployer /
                                totalNumberOfUsersSignedUpOnChamberPerksApp) *
                                100
                            ).toFixed(0)}
                            %)
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          Without an employer selected:{" "}
                          <span
                            style={{
                              color: "#E1A731",
                            }}
                          >
                            {totalNumberOfUsersSignedUpWithoutAnEmployer} (
                            {Math.round(
                              (totalNumberOfUsersSignedUpWithoutAnEmployer /
                                totalNumberOfUsersSignedUpOnChamberPerksApp) *
                                100
                            ).toFixed(0)}
                            %)
                          </span>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ),
          },
          {
            boxTitle: enumStrings.rootAdminText.viewStatBoxes.membersInCPAText,
            boxContent: (
              <div style={{ width: "100%" }}>
                {!data?.getAllChambers ? (
                  <Loader />
                ) : (
                  (data?.getAllChambers || []).length - 2 // remove "-2" when done deleting Greater Kingston and Halton Hills
                )}
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "5px",
                    padding: "30px",
                    paddingTop: "0",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {
                        enumStrings.rootAdminText.viewStatBoxes
                          .totalMemberCompanies
                      }
                      :{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {(exportChamberMemberUsersCsvListData || []).length}
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Companies Offering Live Perks:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {numberOfCompaniesOfferingLivePerks} (
                        {percentOfCompaniesOfferingLivePerks})
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "35px",
                      paddingBottom: "0px",
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                      }}
                    >
                      100 Newest Members
                    </span>
                    <RootAdminStatTable
                      headCells={[
                        {
                          id: "chamberMemberName",
                          label: "Member Name",
                        },
                        {
                          id: "chamberName",
                          label: "Chamber Name",
                        },
                        {
                          id: "createdAtUtcMilli",
                          label: "Creation Date",
                          convertUtcToTimestamp: true,
                          defaultSort: true,
                          sortOrder: "desc",
                        },
                      ]}
                      loading={(newestMembers || []).length === 0}
                      allChartData={newestMembers}
                      overflowHorizontally
                      chartSize="medium"
                    />
                  </div>
                </div>
              </div>
            ),
          },

          {
            boxTitle: enumStrings.rootAdminText.viewStatBoxes.totalPerks,
            boxContent: (
              <div style={{ width: "100%" }}>
                {totalNumberOfLivePerks}
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "5px",
                    padding: "30px",
                    paddingTop: "0",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Total Perk Views:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {exportPerksCsvDiscountListData[0].discountViews}
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Total Perk Redemptions:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {exportPerksCsvDiscountListData[0].discountRedemptions}
                      </span>
                    </span>
                  </div>

                  <br />
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {
                        enumStrings.rootAdminChamberText
                          .numberOfLivePerksOfferedTo
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        All Members:
                      </span>{" "}
                      {numOfAllMemberPerks}
                    </span>
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        Local Members Only:
                      </span>{" "}
                      {numOfLocalMemberOnlyPerks}
                    </span>
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        Anyone:
                      </span>{" "}
                      {numOfGeneralPublicPerks}
                    </span>
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        Member Company Employees Only:
                      </span>{" "}
                      {numOfCompanyEmployeesOnlyPerks}
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "35px",
                      paddingBottom: "0px",
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                      }}
                    >
                      100 Newest Perks
                    </span>
                    <RootAdminStatTable
                      headCells={[
                        {
                          id: "discountName",
                          label: "Title",
                        },
                        {
                          id: "offeredTo",
                          label: "Redeemable By",
                        },
                        {
                          id: "chamberName",
                          label: "Chamber Name",
                        },
                        {
                          id: "discountViews",
                          label: "Views",
                        },
                        {
                          id: "discountRedemptions",
                          label: "Redemptions",
                        },
                        {
                          id: "createdAtUtcMilli",
                          label: "Creation Date",
                          convertUtcToTimestamp: true,
                          defaultSort: true,
                          sortOrder: "desc",
                        },
                        {
                          id: "deletedAtUtcMilli",
                          label: "Deletion Date",
                          convertUtcToTimestamp: true,
                        },
                      ]}
                      loading={(newestPerks || []).length === 0}
                      allChartData={newestPerks}
                      overflowHorizontally
                    />
                  </div>
                </div>
              </div>
            ),
          },
          {
            fullWidth: true,
            boxTitle: enumStrings.rootAdminText.viewStatBoxes.chamberDetails,
            boxContent: (
              <RootAdminStatTable
                overflowHorizontally
                chartSize="large"
                headCells={[
                  {
                    id: "chamberName",
                    label: enumStrings.rootAdminText.chamberBoardLead
                      .split("/")
                      .join("/ "),
                    sticky: true,
                  },
                  {
                    id: "subscriptionUTCMilli",
                    label: "Subscription Start Date",
                    utcMilliTimestampObjKey: "subscriptionTimestamp",
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                  {
                    id: "numberOfAppUsers",
                    label: "App users who've selected this chamber",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfAppUsersWithEmployerSelected",
                    label:
                      "App users who've selected an employer from this chamber",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfAppUsersWithoutEmployerSelected",
                    label:
                      "App users who've selected this chamber and not any chamber's employer",
                  },
                  {
                    id: "numOfLivePerks",
                    label: "Live Perks",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfPerkViews",
                    label: "Views",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfPerkRedemptions",
                    label: "Redemptions",
                  },
                  {
                    id: "numOfInitialMemberCompanies",
                    label: "Initial Member Companies",
                  },
                  {
                    id: "numOfMemberCompanies",
                    label: "Current Member Companies",
                    disableRightBorder: true,
                  },
                  {
                    id: "numOfMemberCompanyDelta",
                    label: "#",
                    disableRightBorder: true,
                    deltaType: "numOfMemberCompanyDeltaType",
                  },
                  {
                    id: "percentOfMemberCompanyDelta",
                    label: "%",
                    isPercent: true,
                    deltaType: "numOfMemberCompanyDeltaType",
                  },

                  {
                    id: "numOfCompaniesOfferingLivePerks",
                    label: "Companies Offering Live Perks",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentOfCompaniesOfferingLivePerks",
                    label: "%",
                    isPercent: true,
                  },
                  {
                    id: "numOfCompaniesLoggedInToAdminPortal",
                    label: "Companies Logged In To Dashboard",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentOfCompaniesLoggedInToAdminPortal",
                    label: "%",
                    isPercent: true,
                  },
                ]}
                loading={chamberPerkOfferBreakdown.length === 0}
                allChartData={chamberPerkOfferBreakdown}
              />
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.perkPerMemberCategory,
            boxSubTitle:
              enumStrings.rootAdminText.viewStatBoxes
                .perkPerMemberCategorySubTitle,
            boxContent: (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "categoryName",
                    label: enumStrings.rootAdminText.viewStatBoxes.categoryName,
                    splitOn: "/",
                  },
                  {
                    id: "livePerks",
                    label:
                      enumStrings.rootAdminText.viewStatBoxes.numberOfPerks,
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                  {
                    id: "rawPercentageNumber",
                    label:
                      enumStrings.rootAdminText.viewStatBoxes.percentOfPerks,
                    isPercent: true,
                  },
                ]}
                loading={
                  (livePerksPerMemberCategoryBreakdown || []).length === 0
                }
                allChartData={livePerksPerMemberCategoryBreakdown}
              />
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.chamberPerksPerCategory,
            boxSubTitle:
              enumStrings.rootAdminText.viewStatBoxes
                .perkPerMemberCategorySubTitle,
            boxContent: livePerksPerMemberCategoryBreakdown ? (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "chamberName",
                    label: enumStrings.rootAdminText.chamberBoardLead,
                    sticky: true,
                  },
                  ...[
                    ...livePerksPerMemberCategoryBreakdown.map(
                      ({ categoryId, categoryName }) => ({
                        id: categoryId,
                        label: categoryName.split("/").join("/ "),
                      })
                    ),
                  ],
                ]}
                loading={chamberPerksPerCategoryBreakdown.length === 0}
                allChartData={chamberPerksPerCategoryBreakdown}
                overflowHorizontally
              />
            ) : (
              <></>
            ),
          },

          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.exportChamberLeads,
            boxContent: (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "chamberName",
                    label: enumStrings.rootAdminText.chamberBoardLead,
                  },
                  {
                    id: "createdAtUtcMilli",
                    label: enumStrings.rootAdminText.creationTimeLocalTZ,
                    convertUtcToTimestamp: true,
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                ]}
                loading={!csvContactableChamberData}
                allChartData={csvContactableChamberData}
              />
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.exportChamberMemberLeads,
            boxContent: (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "chamberName",
                    label: enumStrings.rootAdminText.viewStatBoxes.chamberName,
                  },
                  {
                    id: "name",
                    label: enumStrings.rootAdminText.memberCompanyLead,
                  },
                  {
                    id: "createdAtUtcMilli",
                    label: enumStrings.rootAdminText.creationTimeLocalTZ,
                    convertUtcToTimestamp: true,
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                ]}
                loading={!csvContactableComapniesData}
                allChartData={csvContactableComapniesData}
              />
            ),
          },
        ]}
      />
    </span>
  );
};

export default RootAdmin;
